import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/setting-:urlManager.html',
    component: () => import('../views/Setting.vue'),
    props: true
  },
  {
    path: '/setting.html',
    component: () => import('../views/Setting.vue')
  },
  {
    path: '/manager-:urlPage.html',
    component: () => import('../views/Manager.vue'),
    props: true
  },
  {
    path: '/coupon-:urlPage.html',
    component: () => import('../views/Coupon.vue'),
    props: true
  },
  {
    path: '/url.html',
    component: () => import('../views/Url.vue')
  },
  {
    path: '/error.html',
    component: () => import('../views/Error.vue')
  },
  {
    path: '/:pathMach(.*)*',
    redirect: '/error.html'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
